import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { ValidatorsService } from '../../shared/services/validators.service';
import { UserSignUpRequest } from '../../shared/intarfaces';

@Component({
  selector: 'signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {
  @AutoUnsubscribe()
  authSub: Subscription | undefined;

  private readonly EMAIL_REGEX: string = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';
  emailMinLength = 3;
  emailMaxLength = 500;
  nameMinLength = 2;
  nameMaxLength = 50;

  form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(this.emailMinLength),
      Validators.maxLength(this.emailMaxLength),
      Validators.pattern(this.EMAIL_REGEX)
    ]),
    password: new FormControl('', this.validatorsService.passwordValidators(true, false)),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
    ]),
    agreement: new FormControl(false, [Validators.requiredTrue])
  });

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    private router: Router,
    public validatorsService: ValidatorsService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authSub = this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigateByUrl('profile');
        }
      }
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.authService.hashPassword(this.form.value.password!, this.form.value.email!).then((hash) => {
      const userSignUpRequest = { ...this.form.value, password: hash } as UserSignUpRequest;
      this.authService.sendRegisterRequest(userSignUpRequest).subscribe((response) => {
        if (response.success && response.data) {
          this.authService.successAuthorize(response.data);
        }
      });
    });
  }
}
